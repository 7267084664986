import { useState } from 'react';
import validationMessages from './messages.json';
import validationRules from './rules';

const Validator = (rules) => {

  const [validationErrors, setErrorMessages] = useState({});

  const validate = (name, value) => {
    const rulesForField = rules[name];
    const newErrorMessages = [];

    if (rulesForField) {
      for (const rule of rulesForField) {
        const [ruleName, ...ruleArgs] = rule.split(':');
        const rules_ = ruleArgs[0]?.split(',');

        if (rules_) {
          rules_.forEach((rule_) => {
            const { result, pattern, type } = validationRules[ruleName](name, value, rule_);
            if (!result) {
                const errorMessage = getMessage(ruleName, pattern);
              errorMessage && newErrorMessages.push(errorMessage.replace('_', ' '));
            }
          });
        } else {
          const { result, pattern } = validationRules[ruleName](name, value);
          if (!result) {
              const errorMessage = getMessage(ruleName, pattern);
              errorMessage && newErrorMessages.push(errorMessage.replace('_', ' '));
          }
        }
      }
    }

    if (newErrorMessages.length > 0) {
      setErrorMessages((prevError) => ({ ...prevError, [name]: newErrorMessages }));
    }else{
      setErrorMessages((prevError) => {
          const { [name]: _, ...remainingErrors } = prevError;
          return { ...remainingErrors, ...newErrorMessages };
      });
    }
    return newErrorMessages;
  };

  const getMessage = (rule, pattern) => {
      const messages = validationMessages[rule];
      const keys = Object.keys(pattern).filter(key => key !== 'type');

      if (typeof messages === 'string') {
          return replacePlaceholders(messages, pattern, keys);
      } else if (typeof messages === 'object' && messages[pattern.type]) {
          return replacePlaceholders(messages[pattern.type], pattern, keys);
      } else {
          return null;
      }
  };

  const replacePlaceholders = (message, pattern, keys) => {
      let replacedMessage = message;
      for (const key of keys) {
        replacedMessage = replacedMessage.replace(`:${key}`, pattern[key]);
      }
      return replacedMessage;
  };


  return { validate, validationErrors };
  
};


export default Validator;
