import { useRequest } from "../handlers"
import { appURL } from "../utilities/helpers"
import Cookies from 'js-cookie'
import { syncRemoteToLocal, syncLocalToRemote} from "api"

const AuthService = () => {

    const {errors: requestErrors, response, load, request} = useRequest()   
    
    //Logs In the User
    const login = async (data) => {
        request({
            method: 'post',
            url: `${appURL}/api/login`,
            data
        }).then(() => {
            // syncLocalToRemote()
        }).catch(() => {})
    }

    //Logs Out the User
    const logout = async () => {
        request({
            method: 'post',
            url: `${appURL}/api/logout`,
        }).then(() => {
            onLogout()
        }).catch(() => {})
    }

    //On Logout
    const onLogout = () => {
        removeAuthCookie()
        removeVerificationCookie()
    }

    //Remove Auth Cookie
    const removeAuthCookie = () => {
        Cookies.remove('auth')
    }

    //Remove Verification Cookie
    const removeVerificationCookie = () => {
        Cookies.remove('verification')
    }

    return {
        load,
        response,
        requestErrors,
        logout,
        login
    }

}

export default AuthService