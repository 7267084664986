import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Spinner,
  Dialog,
  DialogBody,
  DialogFooter,
  InputGroup,
  Text,
  FormGroup
} from '@blueprintjs/core';
import { RiGoogleFill, RiAppleFill, RiFacebookCircleFill, RiCloseLine} from '@remixicon/react';
import { useUserService, useAuthService } from '../../services/index';
import { useValidator } from '../../handlers/index';
import { frontEndURL, googleAuthUrl, facebookAuthUrl } from '../../utilities/helpers';

export const Login = ({isOpen, onOpen}) => {

    const [errors, setError] = useState({})
    const [togglePass, setTogglePass] = useState(false)

    const [data, setData] = useState(
        { 
            email: '', 
            password: ''
        }
    );

    const { requestErrors, load, login, response } = useAuthService()
    
    const {validate, validationErrors} = useValidator({
        email: ["required", "email"],
        password: ["required", "string", "min:8"]
    })

    //Handles Form Inputs
    const onHandleChange = (name, value) => {
        //Sets Input to State
        setData((prevData) => ({ ...prevData, [name]: value }));

        //Validate Input
        validate(name, value)
    };

    //Adds Validation Errors to Error State
    useEffect(() => {
        setError(validationErrors)
    }, [validationErrors])

    //Adds Request Errors to Error State
    useEffect(() => {
        setError(requestErrors)
    }, [requestErrors])

    useEffect(() => {
        response?.user && window.location.reload()
    }, [response])

    //Disable Submit Button On Conditions
    const isDisabled = load || Object.keys(data).some(field => !data[field]) || Object.keys(errors).length > 0;

    const submit = () => {
        if (!isDisabled) {
            setError({})
            login(data)   
        }
    }

    return (
        <div>

            <Dialog isOpen={isOpen} onClose={() => onOpen(false)} canOutsideClickClose={false}>

                <DialogBody className='py-10 px-5 relative'>

                    <div className="absolute top-2 right-2">
                       <Button onClick={() => onOpen(false)} icon={<RiCloseLine />} minimal className='outline-0' /> 
                    </div>
                    
                    <div className="text-center my-5">
                        
                        <div className="mb-10 text-center">
                            <Text className="font-bold text-2xl">Welcome Back</Text>
                            <Text className="text-sm text-gray-400">
                                Enter your details or sign in with a social account to continue.
                            </Text>
                        </div>

                        <div className="my-5 mx-auto lg:w-4/6 w-full">
                            <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-4">
                                <Button onClick={() => {
                                    window.location.href = googleAuthUrl
                                }} className='border-gray-400 py-3 text-black rounded-md outline-0'>
                                    <div className='flex'>
                                        <RiGoogleFill size={20} /> 
                                        <Text className="lg:text-md text-xs">Google</Text>
                                    </div>
                                </Button>

                                <Button onClick={() => {
                                    window.location.href = facebookAuthUrl
                                }} className='border-gray-400 py-3 text-black rounded-m outline-0'>
                                    <div className='flex'>
                                        <RiFacebookCircleFill size={20} /> 
                                        <Text className="lg:text-md text-xs">Facebook</Text>
                                    </div>
                                </Button>
                            </div>
                        </div>

                    </div>  

                    <FormGroup 
                        className='mb-5' 
                        label={'Email Address'}
                        helperText={<Text className='text-red-500'>{errors?.email?.[0]}</Text>}
                    >
                        <InputGroup
                            intent={errors?.email?.[0] ? 'danger' : ''}
                            placeholder="Enter your email address"
                            type={'email'}
                            large={true}
                            value={data.email} 
                            onKeyDown={(e) => e.code === "Enter" && submit()}  
                            onChange={(event) => onHandleChange('email', event.target.value)} 
                        />
                    </FormGroup>

                    <FormGroup 
                        className='mb-5' 
                        label={
                            <div className='flex justify-between'>
                                <Text>Password</Text>
                                <a href={`${frontEndURL}/auth/reset-password`} className="text-primary-500">Forgot Password</a>
                            </div>
                        }
                        helperText={<Text className='text-red-500'>{errors?.password?.[0]}</Text>}
                    >
                        <InputGroup
                            intent={errors?.password?.[0] ? 'danger' : ''}
                            placeholder="Enter your password"
                            type={togglePass ? "text" : "password"}
                            onKeyDown={(e) => e.code === "Enter" && submit()}  
                            onChange={(event) => onHandleChange('password', event.target.value)} 
                            large={true}
                            value={data.password} 
                            rightElement={
                                <Button
                                    className='outline-0'
                                    icon={togglePass ? "unlock" : "lock"}
                                    minimal={true}
                                    onClick={() => setTogglePass(!togglePass)}
                                />
                            }
                        />
                    </FormGroup>

                    <div className="">
                        <Button
                            disabled={isDisabled}
                            loading={load}
                            className='w-full outline-0'
                            text='Login'
                            large={true}
                            onClick={submit}
                        />
                    </div>

                </DialogBody>

            </Dialog>

        </div>
    )

};
