import { useState } from "react"
import { useNotification } from ".."
import Cookies from 'js-cookie'
import { frontEndURL } from "../../utilities/helpers"
import response from '../../utilities/response.json'


const ErrorHandler = () => {
    
    const [errors, setError] = useState({})
    const { notify } = useNotification()

    //On Logout
    const onLogout = () => {
        Cookies.remove('auth')
        Cookies.remove('verification')
        window.history.push(`${frontEndURL}/auth/login`)
    }

    const handleError = (error) => {
        
        const { data, status } = error?.response || {};

        // Check for network error
        if (error.message === 'Network Error') {
            error.message = "Please check, you've lost your internet connection!";
        }

        //Navigate to Login Screen If Unauthenticated
        if (status === 401) {
            onLogout()
        }
    
        //Set Satus 409 Errors
        status !== 409 && data?.errors && setError(data?.errors);
        status === 409 && data?.message && setError({error: data?.message});
        
        const msg = status === 409 ? data?.message : ( data?.errors?.message || response?.error?.[status?.toString()] || error?.message)

        // Notify on error
        notify({
            status: 'error',
            msg
        })

    }

    return {
        errors,
        handleError
    }

}

export default ErrorHandler