import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Navbar,
  Alignment,
  AnchorButton,
  NavbarDivider,
  EditableText,
  Popover,
  Image
} from '@blueprintjs/core';

import FaGithub from '@meronex/icons/fa/FaGithub';
import FaDiscord from '@meronex/icons/fa/FaDiscord';
import FaTwitter from '@meronex/icons/fa/FaTwitter';
import BiCodeBlock from '@meronex/icons/bi/BiCodeBlock';
import MdcCloudAlert from '@meronex/icons/mdc/MdcCloudAlert';
import MdcCloudCheck from '@meronex/icons/mdc/MdcCloudCheck';
import MdcCloudSync from '@meronex/icons/mdc/MdcCloudSync';
import styled from 'polotno/utils/styled';

import { useProject } from '../../project';

import { FileMenu } from './components/file-menu';
import { DownloadButton } from './components/download-button';
import { UserButton } from './components/user-button';

const NavbarContainer = styled('div')`
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
  }
`;

const NavInner = styled('div')`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;

export default observer(({ store }) => {
  const project = useProject();

  return (
    <NavbarContainer className="flex justify-between gap-x-4 items-center bp5-navbar">

        <div className="max-w-1/2 flex justify-start gap-x-4 items-center">

          <div style={{width: 45}}>
            <img src="/favicons/android-chrome-512x512.png" alt="Logo" />
          </div>


            <div className=''>
              <FileMenu store={store} project={project} />
            </div>

            <div className='max-w-52'>
                <EditableText
                  value={window.project.name}
                  placeholder="Design name"
                  onChange={(name) => {
                    window.project.name = name;
                    window.project.requestSave();
                  }}
                />
            </div>

        </div> 

        <div className="max-w-1/2 flex justify-end items-center">
            <UserButton store={store} />
            <DownloadButton store={store} />
        </div>

    </NavbarContainer>
  );
});
