import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Position,
  Menu,
  HTMLSelect,
  Slider,
  Popover,
  ProgressBar,
  MenuItem,
  MenuDivider,
  Spinner,
  Text
} from '@blueprintjs/core';
import { RiUserLine, RiLoginCircleLine, RiLogoutCircleLine, RiShoppingBag3Line, RiUserFollowLine} from '@remixicon/react';
import { Login } from '../../auth/login';
import { useUserService, useAuthService } from '../../../services/index';
import { frontEndURL } from '../../../utilities/helpers';

export const UserButton = observer(({ store }) => {

    const { userData, response:userResponseData, load:userLoad } = useUserService()
    const { logout, load:logOutLoad, response:logoutData, load:logoutLoad } = useAuthService()
    const [isOpen, onOpen] = useState(false)

    useEffect(() => {
        initUser()
    }, [])

    useEffect(() => {
        logoutData && window.location.reload()
    }, [logoutData])

    const initUser = () => {
        userData()
    }

    const initLogout = () => {
        logout()
    }

    const { user } = userResponseData || {}

    return (
        <div className="mr-4">


            <Popover content={
                <Menu>
                    {
                        user?.first_name ? 
                            <>
                                <MenuItem icon={<RiUserLine />} onClick={() => {
                                    const url = `${frontEndURL}/account`
                                    window.open(url, '_blank');
                                }} text="My Account" />
                                <MenuItem icon={<RiShoppingBag3Line />} onClick={() => {
                                    const url = `${frontEndURL}/account/orders`
                                    window.open(url, '_blank');
                                }} text="My Orders" />
                            </>
                        :
                            <>
                                <MenuItem icon={<RiLoginCircleLine />} onClick={() => onOpen(true)} text="Login" />
                                <MenuItem alignText="left" icon={<RiUserLine />} onClick={() => {
                                    window.location.href = `${frontEndURL}/auth/register`
                                }} text="Register" />
                            </>
                    }
                    {
                        user?.first_name &&
                        <>
                            <MenuDivider />
                            <MenuItem icon={<RiLogoutCircleLine />} onClick={initLogout} text="Logout" intent="danger" />
                        </>
                    }
                </Menu>
            } placement="bottom">
                <div className="flex items-center cursor-pointer">
                    <Button className='mr-1' icon={(userLoad || logoutLoad) ? <Spinner size={20} /> : user?.first_name ? <RiUserFollowLine /> : <RiUserLine />} />
                    {user?.first_name && <Text className='lg:block hidden font-medium text-md'>{user?.first_name}</Text>}
                </div>
            </Popover>

            <Login isOpen={isOpen} onOpen={onOpen} />

        </div>
    )

});
