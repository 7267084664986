import { useRequest } from "../handlers"
import { appURL } from "../utilities/helpers"
import axios from "axios"


// Design Templates
export const templates = async (data) => {
    try {
        const response = await axios.post(`${appURL}/api/design/templates`, data);
        return response.data.data;
    } catch (error) {
        // throw error.response ? error.response.data : error.message;
    }
}

// User Designs 
export const userDesigns = async () => {
    try {
        const response = await axios.get(`${appURL}/api/design/user-designs`);
        return response.data.data;
    } catch (error) {
        // throw error.response ? error.response.data : error.message;
    }
}

// Save User Design
export const saveUserDesign = async (data) => {
    try {
        const response = await axios.post(`${appURL}/api/design/save-design`, data);
        return response.data;
    } catch (error) {
        // throw error.response ? error.response.data : error.message;
    }
}

// Delete User Design
export const deleteUserDesign = async (data) => {
    try {
        const response = await axios.post(`${appURL}/api/design/delete-design`, data);
        return response.data;
    } catch (error) {
        // throw error.response ? error.response.data : error.message;
    }
}
