import { nanoid } from 'nanoid';
import localforage from 'localforage';
import { dataURLtoBlob } from './blob';
import { saveUserDesign, userDesigns, deleteUserDesign } from './services/DesignService';


async function writeFile(fileName, data) {
  await localforage.setItem(fileName, data);
}

async function readFile(fileName) {
  return await localforage.getItem(fileName);
}

async function deleteFile(fileName) {
  return await localforage.removeItem(fileName);
}

async function readKv(key) {
  return await localforage.getItem(key);
}

async function writeKv(key, value) {
  return await localforage.setItem(key, value);
}

export async function listDesigns() {
  return (await readKv('designs-list')) || [];
}

export async function deleteDesign({ id }) {
  const list = await listDesigns();
  const newList = list.filter((design) => design.id !== id);
  await writeKv('designs-list', newList);
  await deleteFile(`designs/${id}.json`);
  await deleteFile(`designs/${id}.jpg`);
  deleteUserDesign({reference: id})
}

export async function loadById({ id }) {
  let storeJSON = await readFile(`designs/${id}.json`);
  const list = await listDesigns();
  const design = list.find((design) => design.id === id);
  // if it is blob, convert to JSON
  if (storeJSON instanceof Blob) {
    storeJSON = JSON.parse(await storeJSON.text());
  } else if (typeof storeJSON === 'string') {
    storeJSON = JSON.parse(storeJSON);
  }

  return { storeJSON, name: design?.name };
}

function convertBlobToDataUrl(blob) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
  });
}

function convertDataUrlToBlob(dataUrl) {
  const [header, base64] = dataUrl.split(',');
  const mimeType = header.match(/:(.*?);/)[1];
  const binaryString = atob(base64);
  const len = binaryString.length;
  const uint8Array = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
  }

  return new Blob([uint8Array], { type: mimeType });
}

export async function syncLocalToRemote() {

  const localDesigns = (await localforage.getItem('designs-list')) || [];

  for (const design of localDesigns) {

    const storeJSON = await localforage.getItem(`designs/${design.id}.json`);
    const preview = await localforage.getItem(`designs/${design.id}.jpg`);
    await writeFile(`designs/${design.id}.json`, storeJSON);
    await writeFile(`designs/${design.id}.jpg`, preview);

    const previewDataUrl = await convertBlobToDataUrl(preview);
    const designs = await saveUserDesign({reference: design.id, name: design.name, preview: JSON.stringify(previewDataUrl), data: storeJSON})

  }

}

export async function syncRemoteToLocal(){
  const designs = await userDesigns()
  loadRemoteDesigns(designs)
}

export async function loadRemoteDesigns(designs) {
  
  designs?.map((design) => {

    const preview = convertDataUrlToBlob(JSON.parse(design.preview))
    const storeJSON = JSON.parse(design.metadata)
    const id = design.reference
    const name = design.name

    setTimeout(() => {
      saveDesign({storeJSON, preview, name, id, sync: true})
    }, 500)

  })

}

export async function backupFromLocalToCloud() {

  const localDesigns = (await localforage.getItem('designs-list')) || [];

  for (const design of localDesigns) {

    const storeJSON = await localforage.getItem(`designs/${design.id}.json`);
    const preview = await localforage.getItem(`designs/${design.id}.jpg`);
    await writeFile(`designs/${design.id}.json`, storeJSON);
    await writeFile(`designs/${design.id}.jpg`, preview);

    const previewDataUrl = await convertBlobToDataUrl(preview);
    const designs = await saveUserDesign({reference: design.id,  name: design.name, preview: JSON.stringify(previewDataUrl), data: JSON.stringify(storeJSON)})


  }
  
  // const cloudDesigns = (await window.puter.kv.get('designs-list')) || [];
  // cloudDesigns.push(...localDesigns);
  // await window.puter.kv.set('designs-list', cloudDesigns);


  // await localforage.removeItem('designs-list');
  // for (const design of localDesigns) {
  //   await localforage.removeItem(`designs/${design.id}.json`);
  //   await localforage.removeItem(`designs/${design.id}.jpg`);
  // }
  // return cloudDesigns.length;

}

export async function saveDesign({storeJSON, preview, name, id, sync}) {
  // console.log('saving');
  if (!id) {
    id = nanoid(10);
  }

  if (!sync) {
    const previewDataUrl = await convertBlobToDataUrl(preview);
    saveUserDesign({data: JSON.stringify(storeJSON), reference: id, preview: JSON.stringify(previewDataUrl), name: name})
  }

  const previewPath = `designs/${id}.jpg`;
  const storePath = `designs/${id}.json`;

  await writeFile(previewPath, preview);
  // console.log('preview saved');
  await writeFile(storePath, JSON.stringify(storeJSON));

  let list = await listDesigns();
  const existing = list.find((design) => design.id === id);
  if (existing) {
    existing.name = name;
  } else {
    list.push({ id, name });
  }

  await writeKv('designs-list', list);
  return { id, status: 'saved' };
}

export const getPreview = async ({ id }) => {
  const preview = await readFile(`designs/${id}.jpg`);
  return URL.createObjectURL(preview);
};

const batchCall = (asyncFunction) => {
  let cachedPromise = null;
  return async (...args) => {
    if (!cachedPromise) {
      cachedPromise = asyncFunction(...args).catch((error) => {
        // Reset cachedPromise on error to allow retry
        cachedPromise = null;
        throw error;
      });
    }
    return cachedPromise;
  };
};

export const listAssets = async () => {
  const list = (await readKv('assets-list')) || [];
  for (const asset of list) {
    asset.src = await getAssetSrc({ id: asset.id });
    asset.preview = await getAssetPreviewSrc({ id: asset.id });
  }
  return list;
};

export const getAssetSrc = async ({ id }) => {
  const file = await readFile(`uploads/${id}`);
  return URL.createObjectURL(file);
};

export const getAssetPreviewSrc = async ({ id }) => {
  const file = await readFile(`uploads/${id}-preview`);
  console.log('file', file);
  return URL.createObjectURL(file);
};

export const uploadAsset = async ({ file, preview, type }) => {
  const list = await listAssets();
  const id = nanoid(10);
  await writeFile(`uploads/${id}`, file);
  await writeFile(`uploads/${id}-preview`, preview);
  list.push({ id, type });
  await writeKv('assets-list', list);

  const src = await getAssetSrc({ id });
  const previewSrc = await getAssetPreviewSrc({ id });
  return { id, src, preview: previewSrc };
};

export const deleteAsset = async ({ id }) => {
  const list = await listAssets();
  const newList = list.filter((asset) => asset.id !== id);
  await writeKv('assets-list', newList);
};
