import { useRequest } from "../handlers"
import { appURL } from "../utilities/helpers"
import { syncRemoteToLocal, syncLocalToRemote} from "api"

const UserService = () => {

    const {errors: requestErrors, response, load, request} = useRequest()   

    //Fetches User Info
    const userData = async () => {

        syncRemoteToLocal()

        request({
            method: 'get',
            url: `${appURL}/api/user/data`,
        }).then(() => {

            syncRemoteToLocal()
            syncLocalToRemote()

        }).catch(() => {})

    }  

    //Fetches User Designs
    const userDesigns = async () => {

        request({
            method: 'get',
            url: `${appURL}/api/design/user-designs`,
        }).then(() => {}).catch(() => {})
        
    }  
    

    return {
        load,
        response,
        requestErrors,
        userData,
        userDesigns
    }

}

export default UserService