import { OverlayToaster } from "@blueprintjs/core";

const NotificationHandler = () => {

    const notify = async ({status, title, msg, position}) => {
        const toaster = await OverlayToaster.createAsync({
            position: position ?? 'top',
        });

        toaster.show({
            message: msg,
            intent: status === 'error' ? 'danger' : status
        })
    }


    return {
        notify
    }

}

export default NotificationHandler