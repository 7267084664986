import { useState, useEffect } from "react"
import axios from "axios"
import { useNotification, useResponse, useError } from ".."


const RequestHandler = () => {
    
    const {errors, handleError} = useError()
    const {response, handleResponse} = useResponse()
    const [load, setLoad] = useState(false)

    const request = async ({url, method, data}) => {

        axios.defaults.withCredentials = true;
        axios.defaults.withXSRFToken = true;

        try {
            setLoad(true);
            const result = await axios({ method, url, data });
            handleResponse(result.data);
            return result;
          } catch (error) {
            handleError(error);
            throw error;
          } finally {
            setLoad(false);
        }
    }

    return {
        load,
        request,
        errors,
        response
    }
    

}


export default RequestHandler