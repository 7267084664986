import localforage from 'localforage';
import { nanoid } from 'nanoid';

const FileService = () => {
  
  const loadJSONFile = (file, store) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      var text = reader.result;
      let json;
      try {
        json = JSON.parse(text);
      } catch (e) {
        alert('Can not load the project.');
      }

      if (json) {
        store.loadJSON(json);
      }
    };
    reader.onerror = function () {
      alert('Can not load Polotno project file.');
    };
    reader.readAsText(file);
  };

  const loadImageFile = (file, store) => {
    var reader = new FileReader();
    reader.onloadend = function () {
        var url = reader.result;
        const img = new Image();
        img.src = url;
        img.onload = () => {
            const scale = Math.min(
                1,
                store.width / img.width,
                store.height / img.height
            );
            const type = file.type.indexOf('svg') > -1 ? 'svg' : 'image';
            store.activePage.addElement({
                type,
                width: img.width * scale,
                height: img.height * scale,
                src: url,
            });
        };
    };
    reader.onerror = function () {
        alert('Can not load image.');
    };
    reader.readAsDataURL(file);
  };

  const loadFile = (file, store) => {
    if (file.type.indexOf('image') >= 0) {
        loadImageFile(file, store);
    } else {
        loadJSONFile(file, store);
    }
  };

  const handleDrop = (ev, store) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  return {
    loadJSONFile,
    loadImageFile,
    loadFile,
    handleDrop,
  }

}

export default FileService