const validationRules = {

    email: (attribute, input) => {
        // Check if the input is a valid email address
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const result = regex.test(input);
        return {
            result,
            pattern: {
                attribute
            }
        }
    },

    password: (attribute, input, type) => {

        let result = false

        switch (type) {
            case 'letters':
                result = /[a-zA-Z]/.test(input);
                return {
                    result,
                    pattern: {
                        attribute,
                        type,
                    }
                }
            case 'mixed':
                result = (/[a-z]/.test(input) && /[A-Z]/.test(input));
                return {
                    result,
                    pattern: {
                        attribute,
                        type,
                    }
                }
            case 'numbers':
                result = /[0-9]/.test(input);
                return {
                    result,
                    pattern: {
                        attribute,
                        type,
                    }
                }
            case 'symbols':
                result = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(input);
                return {
                    result,
                    pattern: {
                        attribute,
                        type
                    }
                }
            default:
                // Default behavior, assuming numeric comparison
                return {
                    result,
                    pattern: {
                        attribute
                    }
                }
        }
    },

    required: (attribute, input) => {
        // Check if the input is required (not null, undefined, or empty string)
        const result = input !== null && input !== undefined && input !== '';
        return {
            result,
            pattern: {
                attribute
            }
        }
    },

    string: (attribute, input) => {
        // Check if the input is a string
        const result = typeof input === 'string';
        return {
            result,
            pattern: {
                attribute
            }
        }
    },

    min: (attribute, input, min) => {
        const type = typeof input
        let result = null
        switch (type) {
            case 'object':
                result = input.length >= min;
                return {
                    result,
                    pattern: {
                        attribute,
                        min,
                        type,
                    }
                }
            case 'file':
                // Assuming input is the file size in kilobytes
                result = input >= min;
                return {
                    result,
                    pattern: {
                        attribute,
                        min,
                        type,
                    }
                }
            case 'number':
                result = input >= min;
                return {
                    result,
                    pattern: {
                        attribute,
                        min,
                        type,
                    }
                }
            case 'string':
                result = input.length >= min;
                return {
                    result,
                    pattern: {
                        attribute,
                        min,
                        type,
                    }
                }
            default:
                // Default behavior, assuming numeric comparison
                result = input >= min;
                return {
                    result,
                    type,
                    pattern: {
                        attribute,
                        min
                    }
                }
        }
    },
    
}


export default validationRules